/* eslint-disable no-undef */
import '../App.css';
import React, { useState } from 'react';
import magicEden from '../assets/me.png';
import twitter from '../assets/x.png';
import scan from '../assets/scan.png';
import logo from '../assets/3.jpg';
import flower2 from '../assets/flower2.gif';
import rocket from '../assets/rocket.gif';
import heart from '../assets/heart.gif';
import song from '../assets/song.gif';
import tea from '../assets/tea.gif';
import wand from '../assets/magic-wand.png';
import logoUp from '../assets/Logo-up.png';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import Footer from '../Components/footer';

const magicEdenL = () => {
    window.open("#");
}

const twitterL = () => {
    window.open("#");
}

const scanL = () => {
    window.open("#");
}

const Home = () => {

    const [_a1, set_a1] = useState(0)
    const [_a2, set_a2] = useState(0)
    const [_a3, set_a3] = useState(0)
    const [_a4, set_a4] = useState(0)
    const [_a5, set_a5] = useState(0)
    const [_a6, set_a6] = useState(0)
    const [_navbarOpen, set_navbarOpen] = useState(0);

    async function closeNav() {
        set_navbarOpen(0);
    }

    async function navbarOpen() {
        set_navbarOpen(1);
    }

    async function closediv() {
        set_a1(0);
        set_a2(0);
        set_a3(0);
        set_a4(0);
        set_a5(0);
        set_a6(0);
    }

    async function a1() {
        set_a1(1);
        console.log("popupdiv Open")
    }

    async function a2() {
        set_a2(1);
        console.log("popupdiv Open")
    }

    async function a3() {
        set_a3(1);
        console.log("popupdiv Open")
    }

    async function a4() {
        set_a4(1);
        console.log("popupdiv Open")
    }

    async function a5() {
        set_a5(1);
        console.log("popupdiv Open")
    }

    async function a6() {
        set_a6(1);
        console.log("popupdiv Open")
    }

    return (
        <div class="allWrap">
            <div class="light2">
                <div class="cont">
                    {_navbarOpen < 1 ?
                        (<div class="miniBarMain">
                            <div class="logo"><img onClick={() => window.location.href = '/'} class="logoPic" src={logo} /></div>

                            <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                        </div>) :
                        (<div class="miniBarMain">
                            <div class="logo"><img onClick={() => window.location.href = '/'} class="logoPic" src={logo} /></div>

                            <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                        </div>)}

                    {_navbarOpen > 0 ?
                        (<div class="littleNav">
                            <div class="icons">
                                <div class="navDiv" onClick={() => window.location.href = '/'}>HOME</div>
                                <div class="navDiv" onClick={() => window.location.href = 'awards'}>AWARDS</div>
                                <div className='iconsMain'>
                                    <div class="socialIcon"><img onClick={magicEdenL} src={magicEden} /></div>
                                    <div class="socialIcon"><img onClick={twitterL} src={twitter} /></div>
                                    <div class="socialIcon"><img onClick={scanL} src={scan} /></div>
                                </div>
                            </div>

                        </div>) : null}

                    <div class="headers">

                        <div class="headers2">

                            <div class="logo" onClick={() => window.location.href = '/'}>
                                <img class="logoPic" src={logo} />
                                <img class="logoUp" src={logoUp} />

                            </div>

                            <div class="right">

                                <div class="icons">
                                    <div class="navDiv" onClick={() => window.location.href = '/'}>HOME</div>
                                    <div class="navDiv" onClick={() => window.location.href = 'awards'}>AWARDS</div>
                                    <div class="socialIcon"><img onClick={magicEdenL} src={magicEden} /></div>
                                    <div class="socialIcon"><img onClick={twitterL} src={twitter} /></div>
                                    <div class="socialIcon"><img onClick={scanL} src={scan} /></div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className='faqMain'>
                        <div className='sectionMain'>
                            <div className='section1'>
                                {/*<img className='nftFAQ' src={pic1} />*/}
                                <div>How can I make these adorable Cockadoodles mine?</div>
                                <img className='wand' onClick={a1} src={wand} />
                            </div>

                            <div className='section1'>
                                <div>What makes these little feathered friends so special?</div>
                                <img className='wand' onClick={a2} src={wand} />
                            </div>

                            <div className='section1'>
                                <div>Are there only a limited number of these cuties to go around?</div>
                                <img className='wand' onClick={a3} src={wand} />
                            </div>

                            <div className='section1'>
                                <div>Can I give my Cockadoodle a cozy new home if I decide to part ways?</div>
                                <img className='wand' onClick={a4} src={wand} />
                            </div>

                            <div className='section1'>
                                <div>Do these charming Cockadoodles come with any extra goodies?</div>
                                <img className='wand' onClick={a5} src={wand} />
                            </div>

                            <div className='section1'>
                                <div>Can I show off my collection to my pals and spread the Cockadoodle love?</div>
                                <img className='wand' onClick={a6} src={wand} />
                            </div>



                            {_a1 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={flower2} alt="Flower" /></div>
                                    </div>
                                </div> : null}

                            {_a2 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup2">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={rocket} alt="Flower" /></div>
                                    </div>
                                </div> : null}



                            {_a3 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup3">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={heart} alt="Flower" /></div>
                                    </div>
                                </div> : null}



                            {_a4 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup4">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={song} alt="Flower" /></div>
                                    </div>
                                </div> : null}



                            {_a5 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup5">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={tea} alt="Flower" /></div>
                                    </div>
                                </div> : null}



                            {_a6 > 0 ?
                                <div className='popUpMain'>
                                    <div id="popup" className="popup6">
                                        <div className="close" onClick={closediv}>✖</div>
                                        <p className='section2'>
                                            To purchase NFTs from Cockadoodles, you can visit our designated marketplace platform, such as OpenSea or Rarible. Simply search for "Cockadoodles" to find our collection, browse through the available NFTs, and follow the prompts to make your purchase using cryptocurrency.
                                        </p>
                                        <div className='flowerDiv'><img src={flower2} alt="Flower" /></div>
                                    </div>
                                </div> : null}


                        </div>
                    </div>

                </div>
                <Footer />
            </div >
        </div >
    )

}

export default Home;
