// TypeScript
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import Web3 from 'web3'

const chainID = process.env.REACT_APP_CHAIN_ID
const APP_NAME = 'Cockadoodles'
const APP_LOGO_URL = 'https://example.com/logo.png'
const APP_SUPPORTED_CHAIN_IDS = [Number(chainID)]

// Initialize Coinbase Wallet SDK
export const coinbaseWallet = new CoinbaseWalletSDK({
  appName: APP_NAME,
  appLogoUrl: APP_LOGO_URL,
  appChainIds: APP_SUPPORTED_CHAIN_IDS
})

// Initialize a Web3 Provider object
export const coinbaseEthereum = coinbaseWallet.makeWeb3Provider()

// Initialize a Web3 object
export const web3Coinbase = new Web3(coinbaseEthereum as any)