/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';
import { coinbaseEthereum, web3Coinbase } from '../coinbase.tsx';
import { useSDK } from "@metamask/sdk-react";
import { useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, useSwitchNetwork, sepolia } from 'wagmi';
import { createPublicClient, parseEther, webSocket } from 'viem';
import { useDisconnect } from 'wagmi';
import Web3 from "web3";
import '../coinbase.tsx';
import magicEden from '../assets/me.png';
import twitter from '../assets/x.png';
import scan from '../assets/scan.png';
import logo from '../assets/3.jpg';
import title from '../assets/Logo.png';
import star from '../assets/star.gif';
import flower from '../assets/flower.gif';
import pfp from '../assets/5.png';
import Carousel from '../Components/carousel';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import metamaskPNG from '../assets/metamask.png';
import walletC from '../assets/walletC.png';
import Coinbase from '../assets/Coinbase.png';
import logoUp from '../assets/Logo-up.png';
import Footer from '../Components/footer';

const magicEdenL = () => {
    window.open("#");
}

const twitterL = () => {
    window.open("#");
}

const scanL = () => {
    window.open("#");
}

let ABI = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "ApprovalCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "BalanceQueryForZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "CreatorTokenBase__InvalidTransferValidatorContract",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "CreatorTokenBase__SetTransferValidatorFirst",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintERC2309QuantityExceedsLimit",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintZeroQuantity",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__MinterCannotBeZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__MinterHasAlreadyBeenAssignedToTokenId",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__OnlyMinterCanChangeRoyaltyFee",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__RoyaltyFeeGreaterThanMaximumAllowed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MutableMinterRoyalties__RoyaltyFeeWillExceedSalePrice",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnerQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnershipNotInitializedForExtraData",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ShouldNotMintToBurnAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferFromIncorrectOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToNonERC721ReceiverImplementer",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "URIQueryForNonexistentToken",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "fromTokenId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "toTokenId",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            }
        ],
        "name": "ConsecutiveTransfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint96",
                "name": "feeNumerator",
                "type": "uint96"
            }
        ],
        "name": "RoyaltySet",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "oldValidator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "newValidator",
                "type": "address"
            }
        ],
        "name": "TransferValidatorUpdated",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "CREATOR_MAX_ROYALTY",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_OPERATOR_WHITELIST_ID",
        "outputs": [
            {
                "internalType": "uint120",
                "name": "",
                "type": "uint120"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_TRANSFER_SECURITY_LEVEL",
        "outputs": [
            {
                "internalType": "enum TransferSecurityLevels",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_TRANSFER_VALIDATOR",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "FEE_DENOMINATOR",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "ROYALTY_PERCENT",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "baseTokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "defaultRoyaltyFeeNumerator",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getPermittedContractReceivers",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getSecurityPolicy",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "enum TransferSecurityLevels",
                        "name": "transferSecurityLevel",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint120",
                        "name": "operatorWhitelistId",
                        "type": "uint120"
                    },
                    {
                        "internalType": "uint120",
                        "name": "permittedContractReceiversId",
                        "type": "uint120"
                    }
                ],
                "internalType": "struct CollectionSecurityPolicy",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenKey",
                "type": "uint256"
            }
        ],
        "name": "getTokenMinterAward",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getTransferValidator",
        "outputs": [
            {
                "internalType": "contract ICreatorTokenTransferValidator",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getWhitelistedOperators",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            }
        ],
        "name": "isContractReceiverPermitted",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isOperatorWhitelisted",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "caller",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            }
        ],
        "name": "isTransferAllowed",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxTokenPurchase",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxTokens",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_numberOfTokens",
                "type": "uint256"
            }
        ],
        "name": "publicMint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "publicMintActive",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "publicMintPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "remainingSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_reserveAmount",
                "type": "uint256"
            }
        ],
        "name": "reserveTokens",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "salePrice",
                "type": "uint256"
            }
        ],
        "name": "royaltyInfo",
        "outputs": [
            {
                "internalType": "address",
                "name": "receiver",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "royaltyAmount",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "_data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "baseURI",
                "type": "string"
            }
        ],
        "name": "setBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newMax",
                "type": "uint256"
            }
        ],
        "name": "setMaxSupply",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newMaxTokenPurchase",
                "type": "uint256"
            }
        ],
        "name": "setMaxTokenPurchase",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newPrice",
                "type": "uint256"
            }
        ],
        "name": "setPublicMintPrice",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint96",
                "name": "royaltyFeeNumerator",
                "type": "uint96"
            }
        ],
        "name": "setRoyaltyFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "enum TransferSecurityLevels",
                "name": "level",
                "type": "uint8"
            },
            {
                "internalType": "uint120",
                "name": "operatorWhitelistId",
                "type": "uint120"
            },
            {
                "internalType": "uint120",
                "name": "permittedContractReceiversAllowlistId",
                "type": "uint120"
            }
        ],
        "name": "setToCustomSecurityPolicy",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "validator",
                "type": "address"
            },
            {
                "internalType": "enum TransferSecurityLevels",
                "name": "level",
                "type": "uint8"
            },
            {
                "internalType": "uint120",
                "name": "operatorWhitelistId",
                "type": "uint120"
            },
            {
                "internalType": "uint120",
                "name": "permittedContractReceiversAllowlistId",
                "type": "uint120"
            }
        ],
        "name": "setToCustomValidatorAndSecurityPolicy",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "setToDefaultSecurityPolicy",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "tokenKeys",
                "type": "uint256[]"
            },
            {
                "internalType": "string[]",
                "name": "minterAward",
                "type": "string[]"
            }
        ],
        "name": "setTokenMinterAwards",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newTokenReserve",
                "type": "uint256"
            }
        ],
        "name": "setTokenReserve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "transferValidator_",
                "type": "address"
            }
        ],
        "name": "setTransferValidator",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "togglePublicMint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggleTokenMinterAwards",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenMinterAwardsActive",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenMinterAwardsImmutable",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenReserve",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawBalance",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

let address = "0xf0bDc91Da79E6fC8f1E3ad6Bdfd64548d3656690";

const Home = () => {

    const apiKey = process.env.REACT_APP_API_KEY;
    const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
    const httpsUrl = process.env.REACT_APP_HTTPS_URL;

    const blockExplorerUrl = process.env.REACT_APP_BLOCK_EXPLORER_URL;
    const chainName = process.env.REACT_APP_CHAIN_NAME;
    const symbol = process.env.REACT_APP_SYMBOL;
    const chainId = process.env.REACT_APP_CHAIN_ID;

    const { open } = useWeb3Modal();
    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork();
    const { disconnect } = useDisconnect();
    const chains = [mainnet, sepolia];
    const [_txnLink, setTxnLink] = useState('');
    const [_maxICanMint, setMaxICanMint] = useState(0);
    const { sdk} = useSDK();
    const { address: walletAddress} = useAccount({

        async onConnect() {
            handleConnect()
        }

    })

    const transport = webSocket(websocketUrl);

    const publicClient = createPublicClient({
        chain: [mainnet, sepolia],
        transport
    })

    const [_totalSupply, settotalSupply] = useState(0)
    const [statusError, setstatusError] = useState("")
    const [statusLoading, setstatusLoading] = useState("")
    const [success, setsuccess] = useState("")
    const [nftMintingAmount, setnftMintingAmount] = useState(1)
    const [_publicMintMsg, set_publicMintMsg] = useState("Mint Here")
    const [_cost, set_publicSaleCost] = useState("")
    const [_max_per_wallet, set_max_per_wallet] = useState(10)
    const [_owner, set_owner] = useState("")
    const [myNFTWallet, setmyNFTWallet] = useState(0)
    const [_public_mint_status, set_public_mint_status] = useState("")
    const [_MAX_SUPPLY, set_MAX_SUPPLY] = useState("")
    const [_navbarOpen, set_navbarOpen] = useState(0)
    const [_connected, setConnected] = useState(false)
    const [_userBalance, set_userBalance] = useState(0)
    const [_walletDivOpen, set_walletDivOpen] = useState(0);
    const [_ErrorMsg1, setErrorMsg1] = useState("");
    const [_MaxICanMint2, setMaxICanMint2] = useState(0);
    const [_maxICanMintIllusion, set_maxICanMintIllusion] = useState(0);
    const [_Minted, setMinted] = useState(false);
    const [_globalWalletAddress, setGlobalWalletAddress] = useState();
    const [_metamaskWalletAddress, setMetamaskWalletAddress] = useState();
    const [_coinbaseWalletAddress, setCoinbaseWalletAddress] = useState();
    const [_metamaskConnected, setMetamaskConnected] = useState(false);
    const [_coinbaseConnected, setCoinbaseConnected] = useState(false);

    async function connect() {
        try {
            const accounts = await sdk?.connect();
            setAccount(accounts?.[0]);
            console.log('connected metamasksdk');
            connectMmWallet();
        } catch (err) {
            console.warn("failed to connect..", err);
        }

    };

    async function closeNav() {
        set_navbarOpen(0);
    }

    async function navbarOpen() {
        set_navbarOpen(1);
    }

    function shortenAddress(_globalWalletAddress) {
        try {
            return (

                _connected || _metamaskConnected || _coinbaseConnected ?
                    _globalWalletAddress.slice(0, 3) + "..." + _globalWalletAddress.slice(-4) :
                    'Connect'

            );
        } catch (error) {
            console.log(error);
        }
    }

    const contract = {
        address: address,
        abi: ABI
    }

    async function openWalletConnectModal() {
        open();
        set_walletDivOpen(0);
    }

    async function walletDivOpen() {
        set_walletDivOpen(1);
    }

    async function walletDivClose() {
        set_walletDivOpen(0);
    }

    async function handleConnect() {
        if (chain.id !== chainId) {
            switchNetwork(chainId)
        }

        var data = await getBalance();
        setmyNFTWallet(Number(data.data));
        console.log("myNFTWallet :" + data.data);
        setConnected(true);

        setGlobalWalletAddress(walletAddress);
    }

    const { refetch: getTotalSupply } = useContractRead({
        ...contract,
        functionName: 'totalSupply',
    })

    const { refetch: getCost } = useContractRead({
        ...contract,
        functionName: 'publicMintPrice',
    })

    const { refetch: getBalance } = useContractRead({
        ...contract,
        functionName: 'balanceOf',
        args: [_globalWalletAddress ? _globalWalletAddress : '0x0000000000000000000000000000000000000000']
    })

    useEffect(() => {

        console.log('WebSocket URL:', websocketUrl);
        console.log('HTTPS URL:', httpsUrl);
        console.log('Block Explorer URL:', blockExplorerUrl);
        console.log('Chain Name:', chainName);
        console.log('Symbol:', symbol);
        console.log('Chain ID:', chainId);
        console.log('Api key:', apiKey);

        try {
            var metamaskConnectedLocalStorage = localStorage?.getItem('isMetamaskWalletConnected');
            if (metamaskConnectedLocalStorage === 'true') {
                connectMmWallet();
            }
        } catch (ex) {
            console.log(ex)
        }

        try {
            var coinbaseConnectedLocalStorage = localStorage?.getItem('isCoinbaseWalletConnected');
            if (coinbaseConnectedLocalStorage === 'true') {
                openCoinbaseWalletModal();
            }
        } catch (ex) {
            console.log(ex)
        }

        async function fetchData() {
            var data = await getTotalSupply();

            settotalSupply(Number(data.data))
            console.log("totalSupplyFromUseffect : " + data.data)

            await new Promise(resolve => setTimeout(resolve, 1000));

        }

        async function fetchData2() {

            var data1 = await getBalance();
            setmyNFTWallet(Number(data1.data));
            console.log("myNFTWallet :" + data1.data);

            var maxIcanMint = Number(_max_per_wallet) - Number(myNFTWallet)
            setMaxICanMint(maxIcanMint);
            console.log("maxIcanMint : " + maxIcanMint);
        }

        async function fetchData3() {

            var data2 = await getCost();
            set_publicSaleCost(Number(data2.data) / 10 ** 18);
            console.log("publicSaleCost :" + data2.data);
        }

        async function globalAddress() {

            console.log("_globalWalletAddress : " + _globalWalletAddress);

            setGlobalWalletAddress(_metamaskWalletAddress);

            await new Promise(resolve => setTimeout(resolve, 1000));

            console.log("_globalWalletAddress : " + _globalWalletAddress);


        }

        async function globalAddressCoinbase() {
            console.log("_globalWalletAddress : " + _globalWalletAddress);

            setGlobalWalletAddress(_coinbaseWalletAddress);

            await new Promise(resolve => setTimeout(resolve, 1000));

            console.log("_globalWalletAddressCoinbase : " + _globalWalletAddress);


        }

        if (_connected) {
            fetchData();
            fetchData2();
            fetchData3();
        }

        if (_metamaskConnected) {
            globalAddress();
            fetchData();
            fetchData2();
            fetchData3();
        }

        if (_coinbaseConnected) {
            globalAddressCoinbase();
            fetchData();
            fetchData2();
            fetchData3();
        }
    }

        // eslint-disable-next-line no-use-before-define
        , [_connected, _metamaskConnected, _coinbaseConnected, getBalance, getCost, getTotalSupply, _txnLink, _maxICanMint]);

    const { data, refetch, isSuccess } = useContractReads({
        contracts: [
            { ...contract, functionName: 'totalSupply' },
            { ...contract, functionName: 'maxTokenPurchase' },
            { ...contract, functionName: 'owner' },
            { ...contract, functionName: 'publicMintPrice' },
            { ...contract, functionName: 'maxTokens' },
            { ...contract, functionName: 'publicMintActive' },
            { ...contract, functionName: 'balanceOf', args: [_globalWalletAddress ? _globalWalletAddress : '0x0000000000000000000000000000000000000000'] },

        ]
    },)

    useMemo(() => {
        if (isSuccess === true) {
            settotalSupply(Number(data[0].result))
            set_max_per_wallet(Number(data[1].result))
            set_owner(data[2].result)
            set_publicSaleCost(Number(data[3].result) / 10 ** 18)
            set_MAX_SUPPLY(Number(data[4].result))
            set_public_mint_status(data[5].result)
            set_userBalance(Number(data[6].result))

            console.log("Max Per Wallet :" + Number(data[1].result));
            console.log("User Balance :" + Number(data[6].result));

            console.log("User Balance :" + Number(data[6].result));

            //setMaxICanMint(Number(data[1].result) - Number(data[6].result));
            setMaxICanMint2(Number(data[1].result) - Number(data[6].result));

        }
    }, [isSuccess])


    const { writeAsync } = useContractWrite({
        ...contract,
        functionName: 'publicMint',
        onError(error) {
            if (error.message.includes('balance')) {
                setstatusError(true)
                setstatusLoading(false)
            }
        }
    })

    async function onPlus() {

        console.log("_public_mint_status :" + _public_mint_status);
        var nftIhave = nftMintingAmount + myNFTWallet;


        console.log("myNFTWallet : " + myNFTWallet);
        console.log("nftMintingAmount : " + nftMintingAmount);

        console.log("nftIhave : " + nftIhave);


        console.log("1");
        if (_public_mint_status) {
            console.log("2");

            if (nftIhave < _max_per_wallet) {
                //if (nftIhave < 1) {
                console.log("3");

                setnftMintingAmount(nftMintingAmount + 1);
                console.log("4");


                //}
            }
        }


    }

    async function onMinus() {
        if (nftMintingAmount != 1) {
            setnftMintingAmount(nftMintingAmount - 1)
        }
    }

    async function onMint() {
        try {
            var mintCost;
            var fullTotalSupply = 0;
            fullTotalSupply = (nftMintingAmount) + (_totalSupply);
            console.log("fullTotalSupply : " + fullTotalSupply);

            if (_owner === _globalWalletAddress) {
                mintCost = '0';
            } else {
                if (fullTotalSupply <= 0) {
                    mintCost = '0';
                } else if (_public_mint_status) {
                    mintCost = _cost;
                    console.log("mintCostB : " + mintCost);
                }
            }

            mintCost = (mintCost * nftMintingAmount).toString();
            console.log("nftMintingAmountA : " + nftMintingAmount);
            console.log("mintCostA : " + mintCost);

            setstatusLoading(true);
            setstatusError(false);
            setsuccess(false);

            var mintedSoFar = Number(myNFTWallet);

            //............................................................//


            var res = await writeAsync({
                args: [nftMintingAmount],
                value: parseEther(mintCost) // mintCost
            });

            await new Promise(resolve => setTimeout(resolve, 2000));


            var result = await publicClient.waitForTransactionReceipt(res);
            if (result.status === 'success') {
                setstatusError(false);
                setsuccess(true);

                setstatusLoading(false);

                console.log("res :" + res.hash);
                console.log("result :" + result.blockHash);

                const transactionLink = `https://sepolia.etherscan.io/tx/${res.hash}`;
                console.log("Transaction Link: " + transactionLink);

                setTxnLink(transactionLink);


                //............................................................//

                setMinted(true);

                console.log("_max_per_wallet : " + _max_per_wallet);
                console.log("myNFTWallet : " + myNFTWallet);

                var maxICanMintIllusion = Number(_max_per_wallet) - Number(mintedSoFar) - Number(nftMintingAmount);
                set_maxICanMintIllusion(maxICanMintIllusion);
                console.log("maxICanMintIllusion : " + maxICanMintIllusion);

                setnftMintingAmount(1);

            } else {
                setsuccess(false);
                setstatusError(true);
                setstatusLoading(false);
            }
        } catch (e) {
            console.log(e);
            console.log("Error Msg @#$@@#:" + e.message);

            if (e.message.includes("err: insufficient funds for gas")) {
                const errorName = "Insufficient funds";
                setErrorMsg1(errorName);
                console.log("error : " + errorName);
                console.log("error msg :" + _ErrorMsg1);
                setstatusLoading(false);



            } else if (e.message.includes("User rejected the request")) {
                const errorName = "";
                setErrorMsg1(errorName);
                console.log("error2 : " + errorName);
                console.log("error msg :" + _ErrorMsg1);
                setstatusLoading(false);



            } else {
                const errorName = "Sorry, something went wrong";
                setErrorMsg1(errorName);
                console.log("error3 : " + errorName);
                console.log("error msg :" + _ErrorMsg1);
                setstatusLoading(false);


            }

        }
    }

    async function onMintMetamask() {
        try {
            var mintCost;
            var fullTotalSupply = 0;
            fullTotalSupply = (nftMintingAmount) + (_totalSupply);
            console.log("fullTotalSupply : " + fullTotalSupply);

            if (_owner === _globalWalletAddress) {
                mintCost = '0';
            } else {
                if (fullTotalSupply <= 0) {
                    mintCost = '0';
                } else if (_public_mint_status) {
                    mintCost = _cost;
                    console.log("mintCostB : " + mintCost);
                }
            }

            mintCost = (mintCost * nftMintingAmount).toString();
            console.log("nftMintingAmountA : " + nftMintingAmount);
            console.log("mintCostA : " + mintCost);

            setstatusLoading(true);
            setstatusError(false);
            setsuccess(false);

            var mintedSoFar = Number(myNFTWallet);

            //............................................................//
            const web3mm = new Web3(window.ethereum);

            // Request access to user accounts
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const accounts = await web3mm.eth.getAccounts();
            const account = accounts[0];

            const contractM = new web3mm.eth.Contract(ABI, address);

            const res = await contractM.methods.publicMint(nftMintingAmount).send({ gasLimit: '685000', from: account, value: parseEther(mintCost) });

            await new Promise(resolve => setTimeout(resolve, 2000));
            console.log("res : " + res);

            const transactionHash = res.transactionHash;
            const transactionStatus = res.status;

            console.log('Transaction Hash:', transactionHash);
            console.log('Transaction Status:', transactionStatus);


            if (transactionStatus) {
                setstatusError(false);
                setsuccess(true);

                setstatusLoading(false);

                const transactionLink = `https://sepolia.etherscan.io/tx/${transactionHash}`;
                console.log("Transaction Link: " + transactionLink);

                setTxnLink(transactionLink);


                //............................................................//

                setMinted(true);

                console.log("_max_per_wallet : " + _max_per_wallet);
                console.log("myNFTWallet : " + myNFTWallet);

                var maxICanMintIllusion = Number(_max_per_wallet) - Number(mintedSoFar) - Number(nftMintingAmount);
                set_maxICanMintIllusion(maxICanMintIllusion);
                console.log("maxICanMintIllusion : " + maxICanMintIllusion);

                setnftMintingAmount(1);


            } else {
                setsuccess(false);
                setstatusError(true);
                setstatusLoading(false);
            }
        } catch (e) {
            console.log(e);
            console.log("Error Msg @#$@@#:" + JSON.stringify(e));
            setstatusLoading(false);

            if (e.error.message.includes("err: insufficient funds for gas")) {
                const errorName = "Insufficient funds";
                setErrorMsg1(errorName);
                console.log("error : " + errorName);
                console.log("error msg :" + _ErrorMsg1);
                setstatusLoading(false);



            } else if (e.error.message.includes("User denied transaction signature")) {
                const errorName = "";
                setErrorMsg1(errorName);
                console.log("error2 : " + errorName);
                console.log("error msg :" + _ErrorMsg1);
                setstatusLoading(false);



            } else {
                const errorName = "Sorry, something went wrong";
                setErrorMsg1(errorName);
                console.log("error3 : " + errorName);
                console.log("error msg :" + _ErrorMsg1);
                setstatusLoading(false);

            }

        }
    }

    async function onMintCoinbase() {
        try {
            var mintCost;
            var fullTotalSupply = 0;
            fullTotalSupply = (nftMintingAmount) + (_totalSupply);
            console.log("fullTotalSupply : " + fullTotalSupply);

            if (_owner === _globalWalletAddress) {
                mintCost = '0';
            } else {
                if (fullTotalSupply <= 0) {
                    mintCost = '0';
                } else if (_public_mint_status) {
                    mintCost = _cost;
                    console.log("mintCostB : " + mintCost);
                }
            }

            mintCost = (mintCost * nftMintingAmount).toString();
            console.log("nftMintingAmountA : " + nftMintingAmount);
            console.log("mintCostA : " + mintCost);

            setstatusLoading(true);
            setstatusError(false);
            setsuccess(false);

            var mintedSoFar = Number(myNFTWallet);

            //............................................................//

            // Request account access
            const accounts = await coinbaseEthereum.request({ method: 'eth_requestAccounts' });

            // Ensure accounts array is not empty
            const userAddress = accounts[0];
            console.log(`User's address is ${userAddress}`);

            // Optionally, set the default account for web3.js
            web3Coinbase.eth.defaultAccount = userAddress;
            const contractM = new web3Coinbase.eth.Contract(ABI, address);

            const res = await contractM.methods.publicMint(nftMintingAmount).send({ gasLimit: '685000', from: userAddress, value: parseEther(mintCost) });

            await new Promise(resolve => setTimeout(resolve, 2000));
            console.log("res : " + res);

            const transactionHash = res.transactionHash;
            const transactionStatus = res.status;

            console.log('Transaction Hash:', transactionHash);
            console.log('Transaction Status:', transactionStatus);


            if (transactionStatus) {
                setstatusError(false);
                setsuccess(true);

                setstatusLoading(false);

                const transactionLink = `https://sepolia.etherscan.io/tx/${transactionHash}`;
                console.log("Transaction Link: " + transactionLink);

                setTxnLink(transactionLink);


                //............................................................//

                setMinted(true);

                console.log("_max_per_wallet : " + _max_per_wallet);
                console.log("myNFTWallet : " + myNFTWallet);

                var maxICanMintIllusion = Number(_max_per_wallet) - Number(mintedSoFar) - Number(nftMintingAmount);
                set_maxICanMintIllusion(maxICanMintIllusion);
                console.log("maxICanMintIllusion : " + maxICanMintIllusion);

                setnftMintingAmount(1);


            } else {
                setsuccess(false);
                setstatusError(true);
                setstatusLoading(false);
            }
        } catch (e) {
            console.log(e);
            console.log("Error Msg @#$@@#:" + JSON.stringify(e));
            setstatusLoading(false);

            if (e.error.message.includes("err: insufficient funds for gas")) {
                const errorName = "Insufficient funds";
                setErrorMsg1(errorName);
                console.log("error : " + errorName);
                console.log("error msg :" + _ErrorMsg1);
                setstatusLoading(false);



            } else if (e.error.message.includes("User denied transaction signature")) {
                const errorName = "";
                setErrorMsg1(errorName);
                console.log("error2 : " + errorName);
                console.log("error msg :" + _ErrorMsg1);
                setstatusLoading(false);



            } else {
                const errorName = "Sorry, something went wrong";
                setErrorMsg1(errorName);
                console.log("error3 : " + errorName);
                console.log("error msg :" + _ErrorMsg1);
                setstatusLoading(false);

            }

        }
    }

    async function disconnectWallet() {

        localStorage.setItem('isMetamaskWalletConnected', "false");
        localStorage.setItem('isCoinbaseWalletConnected', 'false');

        console.log("localStorage?.getItem('isMetamaskWalletConnected') : " + localStorage?.getItem('isMetamaskWalletConnected'));
        console.log("localStorage?.getItem('isCoinbaseWalletConnected') : " + localStorage?.getItem('isCoinbaseWalletConnected'));

        setConnected(false);
        setMetamaskConnected(false);
        setCoinbaseConnected(false);
        disconnect();

        window.location.reload(true);

    }

    async function connectMmWallet() {

        if (typeof window.ethereum !== 'undefined') {
            console.log('MetaMask is installed!');
            set_walletDivOpen(0);

            try {

                const web3mm = new Web3(window.ethereum);

                // Request access to user accounts
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                console.log("metamask on PC")
                const accounts = await web3mm.eth.getAccounts();
                const account = accounts[0];
                setMetamaskWalletAddress(account);

                setGlobalWalletAddress(account);

                await new Promise(resolve => setTimeout(resolve, 1000));

                setMetamaskConnected(true);
                // setConnected(true);


                var data = await getBalance();
                setmyNFTWallet(Number(data.data));
                console.log("myNFTWallet :" + data.data);

                try {
                    localStorage.setItem('isMetamaskWalletConnected', "true");
                } catch (ex) {
                    console.log(ex)
                }


                const chainId = chainId;

                if (window.net_version !== chainId) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: web3mm.utils.toHex(chainId) }],
                        });
                    } catch (err) {
                        // This error code indicates that the chain has not been added to MetaMask.
                        if (err.code === 4902) {

                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    {
                                        chainId: web3Coinbase.utils.toHex(chainId),
                                        rpcUrls: [process.env.ENDPOINT_URL_HTTPS],
                                        chainName: chainName,
                                        nativeCurrency: { name: chainName, decimals: 18, symbol: symbol },
                                    },
                                ],
                            });
                        }
                    }
                }

            } catch (err) {
                console.log(err);
            }
        }
    }

    async function openCoinbaseWalletModal() {
        try {
            // Request account access
            const accounts = await coinbaseEthereum.request({ method: 'eth_requestAccounts' });

            // Ensure accounts array is not empty
            if (accounts.length > 0) {
                const userAddress = accounts[0];
                console.log(`User's address is ${userAddress}`);

                // Optionally, set the default account for web3.js
                web3Coinbase.eth.defaultAccount = userAddress;
                setCoinbaseWalletAddress(userAddress);

                const contract = new web3Coinbase.eth.Contract(ABI, address);
                setGlobalWalletAddress(userAddress);

                await new Promise(resolve => setTimeout(resolve, 1000));

                setCoinbaseConnected(true);

                try {
                    localStorage.setItem('isCoinbaseWalletConnected', "true");
                } catch (ex) {
                    console.log(ex)
                }

                try {
                    const chainId = chainId;

                    // attempt to switch to Harmony One network
                    const result = await coinbaseEthereum.send('wallet_switchEthereumChain', [{ chainId: web3Coinbase.utils.toHex(chainId) }])
                } catch (switchError) {
                    // 4902 indicates that the client does not recognize the Harmony One network
                    if (switchError.code === 4902) {
                        await coinbaseEthereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [{
                                chainId: web3Coinbase.utils.toHex(chainId),
                                rpcUrls: [process.env.ENDPOINT_URL_HTTPS],
                                chainName: chainName,
                                nativeCurrency: { name: chainName, decimals: 18, symbol: symbol },
                                blockExplorerUrls: [blockExplorerUrl],
                                //iconUrls: ['https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png'],
                            }],
                        })
                    }
                }

                set_walletDivOpen(0);

            } else {
                console.error('No accounts found');
            }
        } catch (error) {
            console.error('Error requesting accounts:', error);
        }
    }

    return (
        <div class="allWrap">
            <div class="light">
                <div class="cont">

                    {_navbarOpen < 1 ?
                        (<div class="miniBarMain">
                            <div class="logo"><img class="logoPic" src={logo} /></div>

                            <div class="connect2">
                                {_metamaskConnected || _connected || _coinbaseConnected ?
                                    <button class="connectBtn" onClick={() => disconnectWallet()}>{_globalWalletAddress === '' ? "Connect" : shortenAddress(_globalWalletAddress) /*"Disconnect"*/}</button> :
                                    <button className='connectBtn' onClick={walletDivOpen}>Connect</button>
                                }
                            </div>

                            <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                        </div>) :
                        (<div class="miniBarMain">
                            <div class="logo"><img class="logoPic" src={logo} /></div>

                            <div class="connect2">
                                {_metamaskConnected || _connected || _coinbaseConnected ?
                                    <button class="connectBtn" onClick={() => disconnectWallet()}>{_globalWalletAddress === '' ? "Connect" : shortenAddress(_globalWalletAddress) /*"Disconnect"*/}</button> :
                                    <button className='connectBtn' onClick={walletDivOpen}>Connect</button>
                                }

                            </div>

                            <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                        </div>)}

                    {_navbarOpen > 0 ?
                        (<div class="littleNav">
                            <div class="icons">
                                <div class="navDiv" onClick={() => window.location.href = 'faq'}>FAQ</div>
                                <div class="navDiv" onClick={() => window.location.href = 'awards'}>AWARDS</div>
                                <div className='iconsMain'>
                                    <div class="socialIcon"><img onClick={magicEdenL} src={magicEden} /></div>
                                    <div class="socialIcon"><img onClick={twitterL} src={twitter} /></div>
                                    <div class="socialIcon"><img onClick={scanL} src={scan} /></div>
                                </div>
                            </div>

                        </div>) : null}


                    <div class="headers">

                        <div class="headers2">

                            <div class="logo">
                                <img class="logoPic" src={logo} />
                                <img class="logoUp" src={logoUp} />

                            </div>

                            <div class="right">

                                <div class="icons">
                                    <div class="navDiv" onClick={() => window.location.href = 'faq'}>FAQ</div>
                                    <div class="navDiv" onClick={() => window.location.href = 'awards'}>AWARDS</div>
                                    <div class="socialIcon"><img onClick={magicEdenL} src={magicEden} /></div>
                                    <div class="socialIcon"><img onClick={twitterL} src={twitter} /></div>
                                    <div class="socialIcon"><img onClick={scanL} src={scan} /></div>
                                </div>

                                <div class="connect2">
                                    {_metamaskConnected || _connected || _coinbaseConnected ?
                                        <button class="connectBtn" onClick={() => disconnectWallet()}>{_globalWalletAddress === '' ? "Connect" : shortenAddress(_globalWalletAddress) /*"Disconnect"*/}</button> :
                                        <button className='connectBtn' onClick={walletDivOpen}>Connect</button>
                                    }

                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="introduction">

                        <div class="in2">
                            <div class="intro" onClick={walletDivClose}>
                                <img className='star' src={star} />
                                <img class="titleL" src={title} />


                                <div class="intro2">
                                    Quirky Doodles inspired JPEGS putting the fun back into the NFT space
                                </div>
                            </div>


                            <div class="nftblockWalletConnectedALL">
                                {_globalWalletAddress === undefined ?
                                    <div class="walletConnect">

                                        <button onClick={walletDivOpen} class="button-53" >MINT NOW</button>
                                    </div>
                                    :
                                    null}
                            </div>



                        </div>

                        {_globalWalletAddress === undefined ?

                            (<div class="nftPicDiv">
                                <div class="container">
                                    <div class="shape">
                                        <img class="nftPic" src={pfp} alt='pfp' />
                                    </div>
                                </div>
                            </div>
                            ) : (

                                (<div class="mintDiv">
                                    <div class="totalSupply">{(_cost * nftMintingAmount).toFixed(2)} ETH + GAS</div>

                                    <div class="price"><div>{_totalSupply} / 10000</div></div>

                                    <div class="minting_count_button">

                                        <div class="center">

                                            <button onClick={onMinus} class="btnfos-0-2" type="submit">-</button>

                                        </div>

                                        <div>
                                            <div class="nftminter2">{nftMintingAmount}</div>
                                        </div>


                                        <div class="center">
                                            <button onClick={onPlus} class="btnfos-0-2" type="submit">+</button>

                                        </div>
                                    </div>


                                    {_max_per_wallet <= myNFTWallet ?

                                        (<div class="price"><div>Limit Reached!</div></div>) :
                                        (<div class="price">

                                            {_Minted ?
                                                <div>You can mint {Number(_maxICanMintIllusion) ? Number(_maxICanMintIllusion) : ""} Cockadoodles</div> :
                                                <div>You can mint {Number(_maxICanMint) ? Number(_maxICanMint) : ""} Cockadoodles</div>
                                            }

                                        </div>)}

                                    <div class="mintbuttondiv">
                                        {statusLoading ? (
                                            <div></div>
                                        ) : (
                                            _max_per_wallet <= myNFTWallet ? (
                                                <button class="btnfos-0-3-dis" disabled>
                                                    {_publicMintMsg}
                                                </button>
                                            ) : (
                                                _coinbaseConnected ? (
                                                    <button class="btnfos-0-3" onClick={onMintCoinbase}>
                                                        {_publicMintMsg}
                                                    </button>

                                                ) : _metamaskConnected ? (
                                                    <button class="btnfos-0-3" onClick={onMintMetamask}>
                                                        {_publicMintMsg}
                                                    </button>

                                                ) : _connected ? (
                                                    <button class="btnfos-0-3" onClick={onMint}>
                                                        {_publicMintMsg}
                                                    </button>

                                                ) : (
                                                    <button class="btnfos-0-3-dis" disabled>
                                                        Mint Here
                                                    </button>
                                                )
                                            )
                                        )}
                                    </div>

                                    <div>

                                        {statusError ? (
                                            <div class="errorMessage">
                                                <div >{_ErrorMsg1}</div>
                                            </div>)
                                            : null}

                                        {statusLoading ? (
                                            <div class="loadingContainer">
                                                <div class="loadingText">Minting your NFT</div>
                                            </div>)
                                            : null}

                                        {success ? (
                                            <div>
                                                <div class="successfully">MINTING SUCCESSFUL!</div>

                                                <a className='txnLink' href={_txnLink} target="_blank" rel="noreferrer">View Transaction</a>
                                            </div>

                                        )
                                            : null}

                                    </div>

                                    <div className='flowerDiv'><img className='flower' src={flower} /></div>

                                </div>

                                )
                            )}


                    </div>

                    <Carousel />

                    <Footer />

                </div>
            </div >

            {_walletDivOpen > 0 ?
                <div className='walletDivMain'>
                    <div onClick={walletDivClose} className="close">✖</div>
                    <div class="walletSubDiv" onClick={connect}>
                        <img src={metamaskPNG} />
                        <div className='walletT'>MetaMask</div>
                        <div className='walletC'>Connect to your MetaMask Wallet</div>
                    </div>

                    <div class="walletSubDiv" onClick={openWalletConnectModal}>
                        <img src={walletC} />
                        <div className='walletT'>WalletConnect</div>
                        <div className='walletC'>Scan with WalletConnect to connect</div>
                    </div>

                    <div class="walletSubDiv" onClick={openCoinbaseWalletModal}>
                        <img src={Coinbase} />
                        <div className='walletT'>Coinbase</div>
                        <div className='walletC'>Connect to your Coinbase Wallet</div>
                    </div>
                </div> : null}
        </div >
    )

}
export default Home;
